<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Operaciones</h4>
            <div class="small text-muted">Operaciones de Depósito</div>
          </b-col>
          
          <b-col sm="5">
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card id="list-operations" v-if="table.mostrarFiltros">
        <b-row >
          <b-col sm="10">            
            <b-row>            
              <b-col md="2" class="pr-0">
                <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
              </b-col>
              <b-col md="2" class="pl-0">
                <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
              </b-col>                               
              <b-col sm="2">
                <b-button variant="outline-dark" @click="filterOperations()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="2">
            <b-link>
              <export-excel
                class = 'pull-right mt-3'
                :data = "arr.export"
                worksheet = "Listado de Operaciones en Depósito"
                name = "list-operations-deposit.xls">
                Exportar Datos
              </export-excel>            
            </b-link>
          </b-col>
        </b-row>              
      </b-card>

      <b-row>
        <b-col lg="8" class="pr-0" id="view_list_operations">
          <b-card>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" 
                                  placeholder="Ingrese su busqueda para filtrar la grilla" 
                                  v-model="table.filter"
                                  size="sm">
                    </b-form-input>              
                  </b-input-group>
                </b-form-group>            
              </b-col>
              <b-col>            
                <b-dropdown right text="Nueva Operación" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Nueva Operación">
                  <b-dropdown-item @click="add('add-manual')"><b-icon icon="plus-circle"></b-icon> Ingreso Manual</b-dropdown-item>
                  <b-dropdown-item @click="add('sub-manual')"><b-icon icon="plus-circle"></b-icon> Egreso Manual</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="add('transferencia')"><b-icon icon="plus-circle"></b-icon> Transferencia entre Depósito</b-dropdown-item>
                </b-dropdown>      
              </b-col>
              <b-col sm="12">
                <b-table class="mb-0 table-operations-custom"
                        ref="table"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected"  
                        :busy="table.isBusy"                      
                        v-if="table.items.length || table.isBusy">                       
                    
                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(id)="data">
                      <div v-if="data.item.type">
                        {{data.item.type.name}} #{{data.item.number}}                        
                      </div>
                    </template>

                    <template v-slot:cell(date)="data">
                      {{moment(data.item.date).format('DD/MM/YYYY')}}
                    </template>

                    <template v-slot:cell(staff_id)="data">                      
                      <span v-if="data.item.staff_id" v-b-tooltip.hover :title="'STAFF: ' + data.item.staff.name"> 
                        <b-avatar :src="data.item.staff.image"
                                  v-if="data.item.staff.image">
                        </b-avatar>
                        <b-avatar v-else                                                                         
                                  variant="dark">
                        </b-avatar>              
                      </span>               
                    </template>
                                        
                    <template v-slot:cell(observations)="data">
                      <div v-if="data.item.observations" v-b-tooltip.hover :title="data.item.observations">
                        <b-icon icon="chat-square-text"></b-icon>
                      </div>                                              
                    </template>
                    
                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right" 
                                  @show="setConfigTableOperationsShow"
                                  @hide="setConfigTableOperationsHide">
                        
                        <b-dropdown-item @click="sendPrinterOperation(data.item)">
                          <b-icon icon="printer"></b-icon> Imprimir
                        </b-dropdown-item>                          
                                                
                        <b-dropdown-header v-if="showDelete(data.item)">Acciones</b-dropdown-header>                      
                        <b-dropdown-item @click="remove(data.item)" v-if="showDelete(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="4" id="contain_details_items">
          <div id="details_items">
            <b-card>            
              <b-row>              
                <b-col md="12">
                  
                  <!-- OPERACION SELECCIONADA -->
                  <b-row class="mb-3">
                    <b-col md="8">
                      
                      <div class="crud-operations-title" v-if="itemSelected">
                        <div class="crud-operations-title">
                          <span v-if="itemSelected.type">                          
                            {{itemSelected.type.name}} #{{itemSelected.number}}
                          </span>           
                        </div>
                      </div>
                      
                    </b-col>
                    <b-col md="4">
                      <b-row>
                        <b-col md="12">
                          <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="hideSub()" v-b-tooltip.hover title="Ocultar Detalle">
                            <i class="fa fa-window-close"></i>
                          </b-button>
                        </b-col>                      
                      </b-row>                   
                    </b-col>
                  </b-row>

                  <!-- DETALLE -->                
                  <b-row>
                    <b-col md="12">                         
                      <b-link v-if="tableSub.items.length" 
                              @click="openDetail()"
                              class="pull-right">
                        Ver detalle completo
                      </b-link>                                     
                      <b-table  class="mb-0 table-operations-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSub.items"
                                :fields="tableSub.fields"                            
                                v-if="tableSub.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template>                         

                        <template v-slot:cell(quantity)="row"> 
                          {{row.item.type_balance}} {{row.item.quantity}}
                          <span v-if="row.item.unit_measurement">{{row.item.unit_measurement.reference}}</span>
                        </template>

                        <template v-slot:cell(product)="row">
                          <div v-html="getDetailName(row.item)"></div>
                        </template>

                      </b-table>                      
                      <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                    </b-col>                    
                  </b-row>
                </b-col>
              </b-row>
            </b-card>           
          </div>
        </b-col>
      </b-row>    

      <b-row>
        <b-col lg="8" class="pr-0" id="view_footer_operations">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="filterOperations()" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <form-wizard  title=""
                      subtitle=""
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="save"
                      class="crud-operations-wizard">

          <tab-content title="General" icon="fa fa-edit" :before-change="operationValidDetailGeneral">          
            <b-row>                    
              <b-col md="4">
                <b-form-group label="Fecha">
                  <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>    
             
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea                
                    v-model="crud.form.observations"
                    placeholder="Ingresar las observaciones..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>                          
            </b-row>              
          </tab-content>

          <tab-content title="Detalle" icon="fa fa-list" :before-change="operationValidDetailItems">          
            <b-row>
              <b-col lg="12" md="12" sm="12">                                  
                <Detail @load-detail-products="setDetailItems($event, 'products')" 
                        @load-detail-products-compound="setDetailItems($event, 'productsCompound')" 
                        :operation="crud.form"
                        :detailProducts="crud.form.detailProducts"
                        :detailProductsCompound="crud.form.detailProductsCompound"/>                                             
              </b-col>        
            </b-row>
          </tab-content>
        </form-wizard>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="cancel()">Cancelar</b-button>              
        </div>                
      </b-modal>

      <!-- CRUD DETAILS -->
      <b-modal v-model="modal.detail.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="lg">
        <div slot="modal-header">
          {{this.modal.detail.title}}
        </div>
        <b-row>
          <b-col sm="12">                     
            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="General">
                <table class="table table-hover table-sm" v-if="itemSelected">
                  <tbody>
                    <tr>
                      <td class="crud-operations-title-table-custom align-middle">ID</td>
                      <td class="crud-operations-value-table-custom align-middle">
                        <b>#{{this.itemSelected.id}}</b>
                      </td>
                    </tr>                     
                    <tr v-if="itemSelected.type">
                      <td class="crud-operations-title-table-custom align-middle">Comprobante</td>                      
                      <td class="crud-operations-value-table-custom align-middle">
                        <b>{{this.itemSelected.type.name + ' #' + this.itemSelected.number}}</b>
                      </td>
                    </tr>                                                           
                    <tr v-if="itemSelected.staff">
                      <td class="crud-operations-title-table-custom align-middle">Emisor</td>                      
                      <td class="crud-operations-value-table-custom align-middle">
                        <div>
                          {{itemSelected.staff.name}}  
                        </div>                        
                      </td>
                    </tr>                                                             
                    <tr>
                      <td class="crud-operations-title-table-custom align-middle">Fecha</td>
                      <td class="crud-operations-value-table-custom align-middle">
                        {{moment(this.itemSelected.date).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                                        
                    <tr v-if="itemSelected.observations">
                      <td class="crud-operations-title-table-custom align-middle">Observaciones</td>
                      <td class="crud-operations-value-table-custom align-middle">                        
                        {{this.itemSelected.observations}}
                      </td>
                    </tr>                              
                  </tbody>
                </table>
              </b-tab>              
              <b-tab title="Items">                
                <b-table class="mb-0 table-full-detail-operations"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetail.items"
                        :fields="tableDetail.fields"
                        v-if="tableDetail.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(code)="data">                           
                    <div v-html="getDetailCode(data.item)"></div>
                  </template>

                  <template v-slot:cell(product)="data">                           
                    <div v-html="getDetailName(data.item)"></div>
                  </template>

                  <template v-slot:cell(quantity)="data">   
                    {{data.item.type_balance}} {{data.item.quantity}}
                    <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
                  </template>
                  
                  <template v-slot:cell(deposit)="data">   
                    {{data.item.location.zone.deposit.reference}} / {{data.item.location.zone.reference}} / {{data.item.location.reference}}
                  </template>

                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
            </b-tabs>

          </b-col>
        </b-row>
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.detail.active=false">Cancelar</b-button>                   
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'  
  import Printer from '@/components/inc/printer/printer'
  import Detail from '@/components/inc/cpbtDetailDeposit/detail.vue'
  import moment from 'moment'
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'

  export default {
    components: {
      FindObject,
      Printer,
      Detail,
      FormWizard,
      TabContent,      
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.DEPOSITOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudDepositsOperations',
          elements: {}
        },
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,          
        },     
        tableSub : {
          items: [],
          fields: []
        },            
        tableDetail : {
          items: [],
          fields: []
        },            
        crud: {
          form: {
            id: 0,
            date: '',                        
            type: null,          
            type_id: 0,              
            number: 0,                       
            observations: '',            
            staff_id: 0,            
            detailProducts: [],
            detailProductsCompound: [],    
            reference: '',          
          },            
          print: {
            reference: 'deposit-operation',
            id: 0,
            key: 0,
          }               
        },
        modal: {
          form: {
            active: false,
            title: '',
          },
          detail: {
            active: false, 
            title: '',
          },            
        },          
        arr: {
          operations: [],   
          export: [],           
        },           
        filters: {
          date_start: '',
          date_end: '',          
        },     
        itemSelected: null,             
      }
    },
    created () {     
        /* Configurar permisos de vistas y elementos */
        Helper.hasAccessView(this.access)
        /* Fin configuracion */
    },     
    mounted() {   
      this.loadFieldTable()
      this.filterLoad()   
      this.filterOperations()              
      this.loadStyleConfig()
    },      
    methods: {
      // CONFIGURACION
      loadStyleConfig () {        
        var view_list_operations = document.getElementById('view_list_operations')
        var view_footer_operations = document.getElementById('view_footer_operations')

        var padre = document.getElementById('contain_details_items')
        var hijo = document.getElementById('details_items')  
              
        hijo.style.width = padre.clientWidth - 45 + 'px'

        if(window.innerWidth < 991) {
          hijo.style.width = '100%'
          hijo.style.position = "relative"
          hijo.style.right = 'auto'

          view_list_operations.classList.remove('pr-0')
          view_footer_operations.classList.remove('pr-0')
        } else {          
          view_list_operations.classList.add('pr-0')
          view_footer_operations.classList.add('pr-0')
        }        
      },
      loadFieldTable () {
        // TABLE OPERATIONS        
        this.table.fields.push({key: 'staff_id', label: 'Staff', class:"align-middle text-left", width:"50px"})        
        this.table.fields.push({key: 'date', label: 'Fecha', class:"align-middle text-left", width:"80px"})                            
        this.table.fields.push({key: 'id', label: 'Nº Operación', sortable: true, class:"align-middle text-left", width:"100px"})        
        this.table.fields.push({key: 'observations', label: '', class:"align-middle text-center", width:"100px"})                        
        this.table.fields.push({key: 'f_action', label:'', class:"align-middle", width:"60px"})

        // TABLE DETALLE
        this.tableSub.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle", width:"50px"})                
        this.tableSub.fields.push({key: 'product', label: 'Item', class:"align-middle", width:"150px"})                

        // TABLE DETALLE COMPLETO        
        this.tableDetail.fields.push({key: 'code', label: 'Código', class:"align-middle", width:"50px"})                
        this.tableDetail.fields.push({key: 'product', label: 'Item', class:"align-middle text-truncate", width:"200px"})                                         
        this.tableDetail.fields.push({key: 'deposit', label: 'Deposito/Zona/Ubicación', class:"align-middle text-left", width:"150px"})          
        this.tableDetail.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle text-right", width:"60px"})                     
      },      
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {         
        this.arr.operations.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openSub(element)                             
            }
          }
        })
      },   
      setConfigTableOperationsShow() {
        var arrTable = document.getElementsByClassName('table-operations-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {          
          arrTable[i].style.minHeight = '150px'
        }        
      },
      setConfigTableOperationsHide() {
        var arrTable = document.getElementsByClassName('table-operations-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },  
      setConfigTableSubShow() {
        var arrTable = document.getElementsByClassName('table-operations-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '210px'
        }        
      },
      setConfigTableSubHide() {
        var arrTable = document.getElementsByClassName('table-operations-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },  

      // SELECT         
      prepareExport(data) {              
        this.arr.export = []
        data.forEach(element => {
          var voucherReference = ""
 
          if(element.type) {            
            voucherReference = element.type.name + ' #' + element.number
          }          

          this.arr.export.push({
            id: element.id,
            date: element.date,                                  
            voucherReference: voucherReference,                          
            observations: element.observations,
            productID: 0,
            productCode: "",
            productName: "",            
            productCompoundID: 0,
            productCompoundName: "",                                          
            type_balance: "",
            quantity: 0,
            unitMeasurementID: 0,
            unitMeasurementName: "",                        
          })

          element.activity.forEach(element1 => {
            var productID = 0
            var productCode = ""
            var productName = ""
            var productCompoundID = 0
            var productCompoundName = ""     
            var unitMeasurementID = 0
            var unitMeasurementName = ""               

            if(element1.products_id) {
              productID = element1.product.id
              productCode = element1.product.code
              productName = element1.product.name            
            }

            if(element1.products_colors_id) {
              productID = element1.products_color.id
              productCode = element1.products_color.code
              productName = element1.products_color.name + " (" + element1.products_color.color.name + ")"
            }            

            if(element1.products_waist_id) {
              productID = element1.products_waist.id
              productCode = element1.products_waist.code
              productName = element1.products_waist.name + " (" + element1.products_waist.waist.name + ")"
            }            

            if(element1.products_color_waist_id) {
              productID = element1.products_color_waist.id
              productCode = element1.products_color_waist.code
              productName = element1.products_color_waist.name + " (" + element1.products_color_waist.color.name + " - " + element1.products_color_waist.waist.name + ")"
            }       
          
            if(element1.products_compound_id) {
              productCompoundID = element1.products_compound.id
              productCompoundName = element1.products_compound.name            
            }    

            if(element1.unit_measurement_id) {
              unitMeasurementID = element1.unit_measurement.id
              unitMeasurementName = element1.unit_measurement.name            
            }            

            this.arr.export.push({
              productID: productID,
              productCode: productCode,
              productName: productName,
              productCompoundID: productCompoundID,
              productCompoundName: productCompoundName,                                          
              type_balance: element1.type_balance,
              quantity: element1.quantity,
              unitMeasurementID: unitMeasurementID,
              unitMeasurementName: unitMeasurementName,              
            })
          })
        });
      },

      // ABM MAIN
      add(type) {
        this.crud.form.id = 0           
        this.crud.form.date = moment().format('YYYY-MM-DD')        
        this.crud.form.observations = ''
        this.crud.form.staff_id = Helper.getStaffId()        
        this.crud.form.detailProducts = []
        this.crud.form.detailProductsCompound = []
        this.crud.form.reference = type       

        this.searchVoucher()
        
        this.modal.form.title = "Nueva Operación"
        this.modal.form.active = true
      },       
      remove(item) {
        this.crud.form.id = item.id

        var voucher = "ID #" + item.id
        
        if(item.type) {
          voucher = item.type.name + '- #' + item.number.toString().padStart(8,'0')
        }         

        this.$bvModal.msgBoxConfirm('¿Desea borrar la operación ' + voucher + '?', {
          title: 'Borrar Operación',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarOperacion(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterOperations()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la operación?', {
          title: 'Guardar Operación',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();
            
            if (this.crud.form.id) {
              var result = serviceAPI.editarOperacion(this.crud.form);
            } else {
              var result = serviceAPI.agregarOperacion(this.crud.form);
            }

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterOperations()
              this.$awn.success("Operación guardad con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })              
      },          
      cancel() {
        this.$bvModal.msgBoxConfirm('¿Desea cancelar la operación?', {
          title: 'Cancelar el Operación',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            this.modal.form.active = false
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },
      setDetailItems(event, type) {         
        if(type=="products") {
          this.crud.form.detailProducts = event                
        } 
        if(type=="productsCompound") {          
          this.crud.form.detailProductsCompound = event                
        }              
      },         
      operationValidDetailGeneral() {        
        return true;        
      },
      operationValidDetailItems() {
          if( this.crud.form.detailProducts.length || 
              this.crud.form.detailProductsCompound.length) { 
            return true;
          } else {
            this.$awn.alert("No se cargó ningún item");
            return false;
          }
      },
      showDelete(item) {        
        if(item.type.reference == 'add-manual' || item.type.reference == 'sub-manual' || item.type.reference == 'transferencia' ) {
          return true
        } else {
          return false
        }
      },

      // SELECT VOUCHER 
      searchVoucher() {
        if(!this.crud.form.id) {
          if(this.crud.form.reference) {
            var result = serviceAPI.obtenerVoucherByReference({
              'reference': this.crud.form.reference,              
            });
            result.then((response) => {                               
              this.crud.form.type = response.data                            
              this.crud.form.number = response.data.number  
              
              if( this.crud.form.type ) {              
                this.modal.form.title = this.crud.form.type.name                
                this.modal.form.title = this.modal.form.title + ' #' + this.crud.form.number
              }
                          
            })           
          }
        } 
      },

      // FILTER
      filterLoad() {      
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
      },          
      filterOperations(forceOpenSub=false) {
        this.table.isBusy = true
        var result = serviceAPI.filtrarOperacion(this.filters, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data
          this.arr.operations = data.data
          this.prepareExport(data.data)
          
          this.table.items.forEach(element => {            
            if(forceOpenSub) {
              if(element.id == this.itemSelected.id) {
                this.openSub(element)
              }              
            }                
          });

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }         

          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
          
          this.table.isBusy = false
        })           
        .catch(error => { 
          this.table.isBusy = false  
          this.$awn.alert(ErrorToken.valid(error));
        })  
      },    

      // OPERATION DETAIL
      openSub(item) {                
        this.itemSelected = item        

        this.tableSub.items = item.activity           
        this.loadStyleConfig()
      },    
      hideSub() {
        this.tableSub.items=[]
        this.itemSelected = null
      },               
      openDetail() {              
        this.tableDetail.items = this.itemSelected.activity

        this.modal.detail.title = "Detalle de la operación"
        this.modal.detail.active = true
      }, 
      getDetailCode(item) {        
        if(item.products_id || item.products_colors_id || item.products_waist_id || item.products_color_waist_id) {
          return this.getProductCode(item)
        }            
        if(item.products_compound_id) {          
          return item.products_compound.code
        }                
      },
      getDetailName(item) {        
        if(item.products_id || item.products_colors_id || item.products_waist_id || item.products_color_waist_id) {
          return this.getProductName(item)
        }               
        if(item.products_compound_id) {          
          return item.products_compound.name
        }                
      },       
      getProductCode(item) {
        if(item.products_id) {
          return item.product.code
        }

        if(item.products_colors_id) {
          var prod = item.products_color 
          return prod.code
        }

        if(item.products_waist_id) {
          var prod = item.products_waist 
          return prod.code
        }

        if(item.products_color_waist_id) {
          var prod = item.products_color_waist 
          return prod.code
        }
      },      
      getProductName(item) {
        if(item.products_id) {
          return item.product.name
        }

        if(item.products_colors_id) {
          var prod = item.products_color 
          return prod.name + ' (' + prod.color.name + ')'
        }

        if(item.products_waist_id) {
          var prod = item.products_waist 
          return prod.name + ' (' + prod.waist.name + ')'
        }

        if(item.products_color_waist_id) {
          var prod = item.products_color_waist 
          return prod.name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
        }
      },

      // PRINT
      sendPrinterOperation(item) {
        this.crud.print.key=Math.random()
        this.crud.print.id=item.id
      }      
    }
  }
</script>
<style>
  .crud-operations-title {
    font-size: 15px;
    font-weight: 600;
  } 
  #details_items {
    position: fixed;
    right: 15px;    
  }     
  .table-operations-sub {
    overflow: auto;
    max-height: 350px;
  }
  .crud-operations-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }
  .crud-operations-value-table-custom {
    padding-left: 5px;
  }  
  .table-full-detail-operations {
    overflow: auto;
    max-height: 350px;    
  }
  .operations-alert-retailer {
    font-weight: bold;
    color: gray;
    font-size: 12px;
  }
  .operations-alert-noretailer {
    font-weight: bold;
    color: darkblue;
    font-size: 12px;
  } 
  .operation-total-table-paid {
    color: darkgreen;
    font-weight: 700;
  } 
  .operation-total-table-nopaid {
    color: darkred;
    font-weight: 700;
  }
  .crud-operations-nro-internal {
    color: var(--mdc-theme-primary);
    font-size: 12px;
  }
  .crud-new-operations-button-add {
    margin-top: 29px;
  }
  .crud-operations-totales-items-header {
    background: var(--dark);
    color: #fff;
    font-size: 12px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;    
    text-align: right;
    font-weight: 700;
  }
  .crud-operations-counts-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: solid;
    border-width: 1px;    
  }  
  .crud-operations-totales-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;
    border-width: 1px;    
  }  
  .crud-operations-totales-items-amount {
    font-size: 15px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;    
    border-width: 1px;    
  }    
  .crud-operations-wizard .wizard-header {
    padding: 0px;
  }  
  .crud-operations-totales-discounts-items {
    padding-top: 5px;
  }
</style>